.App {
  text-align: center;
  background-color: #f87c56;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffbda9;
  /* min-height: 50vh; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 5vh;
}

.Nav-links {
  display: flex;
  justify-content: space-evenly;
  border: 3px solid #ff9b7d;
  border-radius: 3px;
}

a:any-link {
  color: white;
  text-decoration: none;
}

.Home {
  min-height: 400vh;
}

.Link-box {
  padding: 3vh;
}

.Link-box:hover {
  background-color: #ff9b7d;
}